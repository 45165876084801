<template>
  <div class="blochchainCard">
    <van-nav-bar  left-arrow  fixed  @click-left="goBack"
                  class="nav">
      <template #title>
        <p class="title">区块链证书详情</p>
      </template>
    </van-nav-bar>

    <div class="wrap-box">
      <div class="blochchainCard-box">

          <img :src="require('../../assets/certificate_head.png')"  alt="" class="image-1">

        <div class="inner-box">
          <van-image :src="require('../../assets/certificate_head2.png')" class="image-2" fit="fitHeight">
            <div class="title">区块链查证信息概览</div>
          </van-image>
          <div class="info-tag">
            文化数据资产资料
          </div>
          <div class="info">
            <van-image :src="require('../../assets/watermark_blue.png')" class="seal-1"></van-image>
            <div class="info-box first-info-box">
              <div class="info-title">徽章名称</div>
              <div class="info-value">{{ listData.productName }}</div>
            </div>
            <div class="info-box">
              <div class="info-title">哈希值</div>
              <div class="info-value" style=" word-wrap:break-word; word-break:break-all;">
                {{ listData.hash_code }}
              </div>
            </div>
            <div class="info-box" v-show="listData.creator != null &&listData.creator != '' ">
              <div class="info-title">创作者</div>
              <div class="info-value">{{ listData.creator }}</div>
            </div>
            <div class="info-box">
              <div class="info-title">编号</div>
              <div class="info-value">{{ listData.number }}</div>
            </div>
            <div class="info-box" v-show="listData.issuer != null &&listData.issuer != ''">
              <div class="info-title">发行方</div>
              <div class="info-value">{{ listData.issuer }}</div>
            </div>
            <div class="info-box">
              <div class="info-title">收藏者</div>
              <div class="info-value">{{ listData.username }}</div>
            </div>
            <div class="info-box" v-show="listData.desc != null &&listData.desc != ''">
              <div class="info-title" style=" word-wrap:break-word; word-break:break-all;">作品介绍</div>
              <div class="info-value">
                {{ listData.desc }}
              </div>
            </div>
          </div>
          <div class="info-tag">
            区块链流转信息
          </div>
          <div class="info2">
            <div v-for="(item, index) in listData.transferList" :key="item.id" class="info3">
              <div class="line-box">
                <div :style="{backgroundColor:index==0 ? 'transparent' : '#5F85E6'}"  class="line1" ></div>
                <div class="round"></div>
                <div :style="{backgroundColor:index==(listData.transferList.length - 1) ? 'transparent' : '#5F85E6'}" class="line" ></div>
              </div>
              <div class="info4">
                <div class="info-box">
                  <div class="info-value">{{ item.username }}</div>
                </div>

                <div class="info-box">
                  <div class="info-title">流转类型</div>
                  <div class="info-value">{{ item.transferTitle }}</div>
                </div>


                <div class="info-box">
                  <div class="info-title">流转时间</div>
                  <div class="info-value">{{ item.generatorTime }}</div>
                </div>



                <div class="info-box">
                  <div class="info-title">哈希值</div>
                  <div class="info-value" style="font-weight: bold">{{ item.transferHashCode }}</div>
                </div>
              </div>
              <van-image :src="require('../../assets/watermark_blue.png')" class="seal-1">
              </van-image>
            </div>
          </div>

        </div>
         <img :src="require('../../assets/certificate_bg2.png')" class="image-3" alt="">
      </div>
    </div>
  </div>

</template>

<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import Certification from "@/components/certification";
import TopBar from "@/components/topBar";
import axios from 'axios'
export default {
  name: "blockchainCertificate",
  setup() {
    const { proxy } = getCurrentInstance();
    const $router = useRouter();
    const showDetail = ref(false);
    const showImage = ref(false)
    let productImgs = ref("");

    const state = reactive({
      listData: []
    });
    let productDetail = JSON.parse($router.currentRoute.value.query.productDetail)
    console.log(productDetail)
    proxy.$http.get("/wenwu-user/order/collection/certificate?id=" + productDetail.id).then(res => {
      state.listData = res.data.data;
    })
    onMounted(async () => {
    });
    const goBack = (() => {
      $router.back(-1)
    })
    return {
      goBack,
      ...toRefs(state),
      // productDetail,
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>


